<template>
  <div>
    <v-card max-width="370" class="mx-auto mt-5" flat>

      <Errors v-bind:errors="dbErrors"/>

      <!-- Anzeige für sonstige Fehler.  Wird geprüft wenn From abgeschickt wird und dann ggf angezeigt-->
      <v-alert 
      v-if="errors.length"
      color="error"
      type="error">
        <div>
          <div v-for="error in errors" v-bind:key="error">{{error}}</div>
          Das Event wurde nicht hinzugefügt.
        </div>
      </v-alert>

      <!-- Anzeige für sich überschneidende Events. Wird geprüft wenn From abgeschickt wird und dann ggf angezeigt -->
      <v-alert 
      v-if="(conflictingEvents.length)"
      color="error"
      type="error">
        <div>
          Zu dieser Zeit ist {{this.room.name}} schon belegt:
          <div v-for="conflict in this.conflictingEvents" v-bind:key="conflict.start">
            {{conflict.start}} - {{conflict.end}}: {{conflict.teacher}}
          </div>
          Das Event wurde nicht hinzugefügt.
        </div>
      </v-alert>

      <!-- Dialog for Conflicts when adding a series -->
      <ConflictingEventsWarning 
        v-bind:datesOfSeriesWithConflicts="this.datesOfSeriesWithConflicts" 
        v-bind:dialogConflictsInSeries="this.dialogConflictsInSeries" 
        @cancelAddingSeries=cancelAddingSeries
        @addSeries=addSeries
      />



    <v-card-title>
      <h4>Neue Nachhilfestunde</h4>
      <v-spacer></v-spacer>
      <v-btn 
      color="error"
      depressed 
      x-small
      @click="resetPrefill"
      >
      Leeren
      </v-btn>
    </v-card-title>

    <!-- Single event or event series button -->
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab :key="'single'">
        Einzelne Stunde
      </v-tab>
      <v-tab :key="'series'" v-if="isAdmin">
        Serie
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-form 
      @submit.prevent
      v-model="valid"
      ref="addEventForm">

      <v-tabs-items v-model="tab">
        <v-tab-item :key="'single'">
          <!-- Datumsfeld -->
          <v-text-field 
          type="date" 
          label="Datum"
          v-model.trim="date"
          :rules="dateRule"
          required
          />

        </v-tab-item>

        <v-tab-item :key="'series'">
          <!-- Erklärungstext -->
          Eine Serie ist für Stunden gedacht, die vertraglich bezahlt werden. Zwischen dem Start- und Enddatum wird an jedem des unten festgelegten Wochentag
           eine Nachhilfestunde erstellt. Davon ausgenommen sind alle schulfreien Tage, die in den
          <router-link to="/settings">Institutseinstellungen</router-link> bearbeitet werden können. <br>
          Wird eine Nachhilfestunde aus einer Serie gelöscht, wird diese automatisch dem Stundenkonto dieses Schülers gutgeschrieben. <br> <br>

          <!-- Startdatumsfeld -->
          <v-text-field 
          type="date" 
          label="Startdatum"
          v-model.trim="date"
          :rules="dateRule"
          required
          />

          <!-- Enddatumsfeld -->
          <v-text-field 
          type="date" 
          label="Enddatum"
          v-model.trim="endOfSeries"
          :rules="dateRule"
          required
          />

          <!-- Wochentag Feld -->
          <v-autocomplete
          :items = weekdays
          label = "Wochentag"
          item-text="name"
          return-object
          v-model.trim="weekday"
          outlined
          :rules="requiredRule"
          required
          />

        </v-tab-item>
      </v-tabs-items>


        <!-- Startzeit Feld -->
        <v-text-field 
        type="time"
        label="Begin"
        v-model.trim="start"
        :rules="timeRule"
        @input="setEndTime"
        required
        />

        <!-- Endzeit Feld -->
        <v-text-field 
        type="time"
        label="Ende"
        v-model.trim="end"
        :rules="timeRule"
        required
        />        

        <!-- Lehrerfeld neu -->
        <v-autocomplete
        :items="this.teachers"
        item-text="name"
        return-object
        label="Lehrer"
        v-model.trim="teacher"
        outlined
        :rules="requiredRule"
        required
        />

        <!-- Student field for admins (with "+" to add students) -->
        <v-autocomplete
        v-if="isAdmin"
        append-outer-icon="mdi-plus"
        @click:append-outer="redirectToAddStudent"
        :items="this.students"
        item-text="name"
        return-object
        label="Schüler"
        v-model.trim="student"
        outlined
        :rules="requiredRule"
        required
        />

        <!-- Student field for non-admins (without "+" to add students) -->
        <v-autocomplete
        v-else
        :items="this.students"
        item-text="name"
        return-object
        label="Schüler"
        v-model.trim="student"
        outlined
        :rules="requiredRule"
        required
        />

        <!-- Raumauswahl -->
        <v-select
        :items="this.rooms"
        item-text="name"
        return-object
        label="Raum"
        v-model.trim="room"
        outlined
        :rules="requiredRule"
        required
        />

        <!-- Fachauswahl -->
        <v-combobox
        :items = "this.subjects"
        label="Fach"
        v-model.trim="subject"
        outlined
        :rules="requiredRule"
        required
        >
        </v-combobox>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="'single'">
            <!-- Begründung Extrastunde -->
            <v-textarea
            outlined
            auto-grow
            counter="200"
            :rules="counterRule"
            background-color="accent"
            label="Begründung"
            v-model.trim="reasonForLesson"
            ></v-textarea>

          </v-tab-item>
        </v-tabs-items>

      </v-form>
    </v-card-text>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="'single'">
        <v-card-actions class="mx-2 mt-n2">
          <!-- Add single Event Button -->
          <v-btn 
          color="primary"
          depressed 
          block
          :disabled="!valid"
          @click="addEvent"
          >
          Einmalig hinzufügen
          </v-btn>
          
        </v-card-actions>
      </v-tab-item>

      <v-tab-item :key="'series'">
        <v-card-actions class="mx-2 mt-n2">
          <!-- Add this Event every week for this term - Button -->
          <v-btn 
          color="primary"
          depressed 
          block
          :disabled="!valid"
          @click="checkDatesForSeries"
          >
          <div v-if="!loadCheckDatesForSeries">
            Als Serie hinzufügen
          </div>
          
          <v-progress-circular
            v-if="loadCheckDatesForSeries"
            indeterminate
            color="white"
          ></v-progress-circular>
          </v-btn>
        </v-card-actions>

      </v-tab-item>
    </v-tabs-items>

    
    </v-card>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import {db, auth, eventsCollection, seriesCollection, studentsCollection, teachersCollection, institutesCollection} from '@/firebase';
import {addMinutes, calculateTimeBetween, formatDate} from '@/assets/js/timeUtils';
import {checkForm, noConflicts, calculateDatesForSeries} from '@/assets/js/addEvent.js';
import ConflictingEventsWarning from '@/components/ConflictingEventsWarning.vue';
import Errors from '@/components/Errors.vue';


export default {
    name: 'AddEvent',

    components: {
      ConflictingEventsWarning,
      Errors,
    },

    data() {
      return {
        tab: null,
        loadCheckDatesForSeries: false,
        dialogConflictsInSeries: false,
        instituteId: '',

        room: {},
        rooms: [],
        teachers: [],
        students: [],
        reasonForLesson: '',
        minEventDuration: null,
        valid: false, //true if ':rules' are fullfilled in every field of the form. Only then button 'Hinzufügen' is clickable         
        conflictingEvents: [],          //array with actually conflicting events with the new one.
        datesForSeries: [],
        datesOfSeriesWithConflicts: [],
        errors: [], //list of other custom errors that can arise in form validation
        dbErrors: [], //errors that arise with the database
        endOfPeriod: '',
        endOfSeries: '',
        weekday: {name: ''},
        weekdays: [{name: 'Montag', index: 1}, {name: 'Dienstag', index: 2}, {name: 'Mittwoch', index: 3}, {name: 'Donnerstag', index: 4}, {name: 'Freitag', index: 5}, {name: 'Samstag', index: 6}, {name: 'Sonntag', index: 0}],

        /* the following are displayed as list to choose from in the form */
        subjects: [
          'Deutsch', 'Englisch', 'Mathe'
        ],

        /* rules for the form */
        requiredRule: [
          v => !!v || 'Pflichtfeld',
        ],
        timeRule: [ //checks if the input is a string of format 'hh:mm'
          v => /([0-1][0-9]|2[0-3]):[0-5]\d/.test(v) || "Gib eine gültige Uhrzeit ein."
        ],
        dateRule: [ //checks if the input is a string of format 'yyyy-mm-dd' and a valid date. Caution! does not test for leap years. The 29.02 is always valid.
          v => /\d{4}-(02-[0-2]\d|(01|03|05|07|08|10|12)-([0-2]\d|3[0-1])|(04|06|09|11)-([0-2]\d|30))/.test(v) || "Gib ein gültiges Datum an."
        ],
        counterRule: [
          v => (v || '' ).length <= 200 || 'Gib maximal 200 Zeichen ein'
        ],
      }
    },

    watch: {
      /* If error is added, scroll to the top of the page to display it */
      dbErrors: function(){
        window.scrollTo(0,0);
      },

      /* that we have a watcher for room and are setting room in the created hook is a workarround. 
      The commented part in computed does not work as expected. this.room is then never updated. This is because
      when updating this.room, only the set is called. Not also the get afterwards. (Its this was for students eg.) */
      room(value) { 
        this.$store.commit('setprefillAddEvent', {key: 'room', value: value})
      }
    },

    computed: {
      ...mapState(['isLoggedIn', 'isAdmin']),

      /* The following getters and setters are needed to use the properties as two-way Computed Properties.
      Then, the prefilled data can be gotten from the state and filling in data is stored in the state.
      See also https://vuex.vuejs.org/guide/forms.html#two-way-computed-property */
      date: {
        get() {
          return this.$store.state.prefillAddEvent.date
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'date', value: value})
        }
      },
      start: {
        get() {
          return this.$store.state.prefillAddEvent.start
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'start', value: value})
        }
      },
      end: {
        get() {
          return this.$store.state.prefillAddEvent.end
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'end', value: value})
        }
      },
      teacher: {
        get() {
          return this.$store.state.prefillAddEvent.teacher
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'teacher', value: value})
        }
      },
      student: {
        get() {
          return this.$store.state.prefillAddEvent.student
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'student', value: value})
        }
      },
      /* room: {
        get() {
          console.log('get')
          return this.$store.state.prefillAddEvent.room
        },
        set (value) {
          console.log('set')
          this.$store.commit('setprefillAddEvent', {key: 'room', value: value})
        }
      }, */
      subject: {
        get() {
          return this.$store.state.prefillAddEvent.subject
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'subject', value: value})
        }
      },
      year: {
        get() {
          return this.$store.state.prefillAddEvent.year
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'year', value: value})
        }
      },
      school: {
        get() {
          return this.$store.state.prefillAddEvent.school
        },
        set (value) {
          this.$store.commit('setprefillAddEvent', {key: 'school', value: value})
        }
      },
      type: {
        get() {
          return this.$store.state.prefillAddEvent.type
        }
      },
    },

    async created() {
      //Get the institueId of the currently logged in user
      this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

      this.getTeachers();
      this.getStudents();
      this.getSettings();
      this.setWeekday();
      this.room = this.$store.state.prefillAddEvent.room;
    },

    methods: {

      /**
       * @description gets the instiute settings (min Duration of an Event, rooms etc) from firestore and setts this.rooms
       */
      async getSettings(){

        //get institute settings
        try{
          const doc = await institutesCollection.doc(this.instituteId).get();
          this.minEventDuration = doc.data().minEventDuration;
          this.endOfPeriod = doc.data().endOfPeriod;
          this.endOfSeries = doc.data().endOfPeriod;
          this.rooms = doc.data().rooms
        } catch {
          this.dbErrors.push({
            text: 'Der Einstellungen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
          throw "Could not find this doument in firestore";
        }
      },

      getTeachers() {
        teachersCollection
        .where('instituteId', '==', this.instituteId)
        .orderBy('firstname')
        .get()
        .then(snapshot => {
          this.teachers = [];
          snapshot.forEach(doc => {
            let teacherName = doc.data().firstname + ' ' + doc.data().lastname
            let teacherRef = teachersCollection.doc(doc.id);
            this.teachers.push({name: teacherName, ref: teacherRef});
          })
        }).catch((error) => {
          console.log("Error getting documents: ", error);
          this.dbErrors.push({
            text: 'Die Lehrer:innen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
        });
      },

      getStudents() {
        studentsCollection
        .where('instituteId', '==', this.instituteId)
        .orderBy('firstname')
        .get()
        .then(snapshot => {
          this.students = [];
          snapshot.forEach(doc => {
            let data = doc.data();
            let studentName = data.firstname + ' ' + data.lastname;
            let studentRef = studentsCollection.doc(doc.id);
            this.students.push({
              name: studentName, 
              year: data.year,
              schooltype: data.schooltype,
              ref: studentRef,
              id: doc.id,
            });
          })
        }).catch((error) => {
          console.log("Error getting documents: ", error);
          this.dbErrors.push({
            text: 'Die Schüler:innen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
        });
      },

      setWeekday() {
        if(this.date != ''){
          /* set this.weekday to the weekday of this.date */
          let weekdayIndex = new Date(this.date + "T12:00:00Z").getDay();
          let weekdayNames = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
          let weekdayName = weekdayNames[weekdayIndex];
          this.weekday = {name: weekdayName, index: weekdayIndex};
        }
      },


      /**
       * @description prefills the end time to: start time + minutes that an event has minimum (this.minEnventDuration)
       */
      setEndTime(){
        this.end = addMinutes(this.start, this.minEventDuration);
      },

      /**
       * @description adds the new Event to firestore, if everything is valid
       */
      async addEvent() {
        //reset database errors
        this.dbErrors = [];

        /* check if all inputs are valid (this.valid) and all the custom rules are valid (this.checkForm) and if there are conflicting events */

        let formIsValid = false;
        [formIsValid, this.errors] = await checkForm(false, this.start, this.end);

        let noConflictsBool = false;
        let somebodyHasNotTimeErrors = [];
        [noConflictsBool, this.conflictingEvents, somebodyHasNotTimeErrors] = await noConflicts({date: this.date, start: this.start, end: this.end, room: this.room, teacher: this.teacher, student: this.student, id: this.eventId}); //is true if there are no conflicting events
        this.errors = this.errors.concat(somebodyHasNotTimeErrors);

        if(this.valid && formIsValid && noConflictsBool){

          /* the type of an event is 'event' except from online-events, where the type is 'online'. */
          var type = 'event';
          if(this.room.name == 'Online'){
            type = 'online';
          }

          try {
            /* Add the new Event to Firestore */
            await eventsCollection.add({
              instituteId: this.instituteId,
              date: this.date,
              start: this.start,
              end: this.end,
              teacherRef: this.teacher.ref,
              studentRef: this.student.ref,
              room: this.room.name,
              roomId: this.room.id,
              subject: this.subject,
              year: this.student.year,
              school: this.student.schooltype,
              type: type,
              seriesReference: '' //add an empty string if it is not part of a series. It is important to add the field to be able to check if it is part of a series or not
            })

            /* add the hour to the hour account */
            let today = formatDate(new Date().toISOString().substr(0,10));
            await this.$store.dispatch('editLessonAccount', {
              studentId: this.student.id, 
              minutes: parseInt(- calculateTimeBetween(this.start, this.end)), 
              notes: this.reasonForLesson + ' [Extrastunde wurde am ' + today + ' hinzugefügt.]', 
              date: this.date,
              subject: this.subject, 
              teacherName: this.teacher.name,
            })

            /* find the route to redirect to */
            let raumplanRoute = '/raumplan/'+ this.date + '/' + this.room.id;          

            /* redirekt to raumplan */
            this.$router.push(raumplanRoute);

            /* clear the form */
            this.resetPrefill();
          } catch(error) {
            this.dbErrors.push({
              text: 'Beim Hinzufügen der Nachhilfestunde ist ein Fehler aufgetreten.', 
              type:'firestore',
            })
            throw "Could not add the event to firestore" + error;
          }

        } else {
          /* scroll to beginning of page to display warning */
          window.scrollTo(0,0);
        }
      },

      /**
       * @description adds the new Event in weekly repetitions to firestore until this.endOfPeriod, if everything is valid
       */
      async checkDatesForSeries() {
        this.dialogConflictsInSeries = false;
        this.datesForSeries = [];
        this.datesOfSeriesWithConflicts = [];

        /* check if all inputs are valid (this.valid) and all the custom rules are valid (this.checkForm). 
        There is also checked, if the first event has any conflicting events. For the follwing event, this is checked in the for loop */
        let formIsValid = false;
        [formIsValid, this.errors] = await checkForm(true, this.start, this.end, this.date, this.endOfSeries);

        if(this.valid && formIsValid){

          this.loadCheckDatesForSeries = true;

          /* calculate all the dates when the event should be added */
          let regularDates = await calculateDatesForSeries(this.date, this.endOfSeries, this.weekday);

          /* for all the dates, of the series, check if there are conflicts */
          for(let i = 0; i<regularDates.length; i++){
            let currentEvent = {date: regularDates[i], start: this.start, end: this.end, room: this.room, teacher: this.teacher, student: this.student};
            let noConflictsBool = false;
            [noConflictsBool, , ] = await noConflicts(currentEvent); //is true if there are no conflicting events
            
            /* if there are no conflicts, add it to the array of dates to be added to firestore. Else add it two an array used for the warning message */
            if(noConflictsBool){
              this.datesForSeries.push(regularDates[i]);
            } else {
              this.datesOfSeriesWithConflicts.push(regularDates[i]);
            }
          }

          /* if there are conflicts, display warning message. Else add events to db */
          if(this.datesOfSeriesWithConflicts.length){
            //display warning message
            this.dialogConflictsInSeries = true;
          } else {
            this.addSeries();
          }
          this.loadCheckDatesForSeries = false;
          
        } else {
          /* scroll to beginning of page to display warning */
          window.scrollTo(0,0);
        }
      },

      async addSeries(){

        if(this.isAdmin){

          /* the type of an event is 'event' except from online-events, where the type is 'online'. */
          var type = 'event';
          if(this.room.name == 'Online'){
            type = 'online';
          }

          if(this.datesForSeries.length){ //check if there are any dates to add
            /* storage of the new created event refs. Used to write this array in eventSeries collection later */
            var eventReferences = [];

            try{

              /* Add the first Event to Firestore. We need to do that separately, because the id of this event is also the id of the
              corresponding eventSeries. And we can only add this to the document after it was created */
              await eventsCollection.add({
                instituteId: this.instituteId,
                date: this.datesForSeries[0],
                start: this.start,
                end: this.end,
                teacherRef: this.teacher.ref,
                studentRef: this.student.ref,
                room: this.room.name,
                roomId: this.room.id,
                subject: this.subject,
                year: this.student.year,
                school: this.student.schooltype,
                type: type,
              })
              .then(docRef => {
                /* add reference of the newly added document to the array */
                eventReferences.push(docRef);
                //add the field "seriesReference" which will be the id of this event
                docRef.update({
                  seriesReference: db.doc(seriesCollection.path + '/' + eventReferences[0].id),
                }).catch((error) => {
                  console.log("Error adding documents: ", error);
                  this.dbErrors.push({
                    text: 'Beim Hinzufügen der Serie ist ein Fehler aufgetreten.', 
                    type: 'help',
                  })
                });
              }).catch((error) => {
                console.log("Error adding documents: ", error);
                this.dbErrors.push({
                  text: 'Beim Hinzufügen der Serie ist ein Fehler aufgetreten.', 
                  type:'help',
                })
              });

              /* add an event to firestore at every date in this.datesOfSeries */
              for (let i = 1; i < this.datesForSeries.length; i++){

                await eventsCollection.add({
                  instituteId: this.instituteId,
                  date: this.datesForSeries[i],
                  start: this.start,
                  end: this.end,
                  teacherRef: this.teacher.ref,
                  studentRef: this.student.ref,
                  room: this.room.name,
                  roomId: this.room.id,
                  subject: this.subject,
                  year: this.student.year,
                  school: this.student.schooltype,
                  type: type,
                  seriesReference: db.doc(seriesCollection.path + '/' + eventReferences[0].id),
                })
                .then(docRef => {
                  /* add reference of the newly added document to the array */
                  eventReferences.push(docRef);
                }).catch((error) => {
                  console.log("Error adding documents: ", error);
                  this.dbErrors.push({
                    text: 'Beim Hinzufügen der Serie ist ein Fehler aufgetreten.', 
                    type:'help',
                  })
                }); 
              }

              /* add the array of references to eventSeries */ 
              //the id of the document will be the id of the first event
              let seriesId = eventReferences[0].id;
              await seriesCollection.doc(seriesId).set({
                instituteId: this.instituteId,
                eventReferences: eventReferences,
                startOfSeries: this.date,
                endOfSeries: this.endOfSeries,
                weekday: this.weekday,
                start: this.start,
                end: this.end,
                teacherRef: this.teacher.ref,
                studentRef: this.student.ref,
                room: this.room.name,
                roomId: this.room.id,
                subject: this.subject,
                year: this.student.year,
                school: this.student.schooltype,
                type: type,
              }).catch((error) => {
                console.log("Error updating documents: ", error);
                this.dbErrors.push({
                  text: 'Beim Hinzufügen der Serie ist ein Fehler aufgetreten.', 
                  type:'help',
                })
              });

              /* save new route for redirection */
              let route = '/series/'+ seriesId;
              /* clear the form */
              this.resetPrefill();
              /* redirekt to raumplan */
              this.$router.push(route);

            } catch(error) {
              this.dbErrors.push({
                text: 'Beim Hinzufügen der Serie ist ein Fehler aufgetreten.', 
                type:'help',
              })
              console.log(error);
            }
           
          } else {
            this.dbErrors.push({
              text: 'Es konnte keine Nachhilfestunde hinzugefügt werden, da für jede Stunde ein Konflikt existiert.', 
            })
            console.log('There are no dates without conflicts')
          }

        } else {
          this.$router.push('/errorrole/admin');
        }
                 
      },

      /* triggered when pressing chancel in ConflinctinEventsWarning Dialog */
      cancelAddingSeries() {
        this.dialogConflictsInSeries = false;
        this.datesForSeries = [];
        this.datesOfSeriesWithConflicts = [];
        this.editType = '';
      },

      /**
       * @description cleares the form and sets it to the standart values that are stored in the store
       */
      resetPrefill() {
        this.$refs.addEventForm.resetValidation()   //reset form validation

        /* reset custom validation */
        this.conflictingEvents = [];
        this.datesForSeries = [];
        this.datesOfSeriesWithConflicts = [];
        this.errors = [];

        this.$store.dispatch('resetPrefillAddEvent') //set values to initial ones
      },

      redirectToAddStudent(){
        this.$router.push('/addstudent')
      }
    }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>